<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M17.659,4.424c0.197,0.195,0.514,0.193,0.707-0.003c0.195-0.196,0.193-0.512-0.002-0.707l-2.529-2.51
      c-0.012-0.012-0.027-0.016-0.04-0.026c-0.038-0.031-0.076-0.062-0.122-0.08c-0.122-0.05-0.26-0.05-0.382,0
      c-0.046,0.019-0.084,0.049-0.122,0.08c-0.013,0.01-0.028,0.015-0.04,0.026l-2.528,2.51c-0.196,0.195-0.197,0.511-0.003,0.707
      c0.098,0.099,0.227,0.148,0.355,0.148c0.127,0,0.255-0.048,0.353-0.145l1.676-1.664v1.21c0,11.424-12.211,11.947-12.731,11.964
      c-0.276,0.009-0.493,0.24-0.484,0.516c0.008,0.271,0.23,0.484,0.499,0.484c0.005,0,0.011,0,0.016,0
      c0.137-0.005,13.7-0.565,13.7-12.964V2.759L17.659,4.424z"/>
    <path
      d="M5.11,11.888c2.711,0,4.916-2.205,4.916-4.916S7.821,2.056,5.11,2.056c-2.71,0-4.916,2.205-4.916,4.916
      S2.399,11.888,5.11,11.888z M5.11,3.056c2.159,0,3.916,1.757,3.916,3.916s-1.757,3.916-3.916,3.916S1.194,9.131,1.194,6.972
      S2.951,3.056,5.11,3.056z"/>
    <path
      d="M4.583,9.735v0.226c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V9.733c0.794-0.2,1.382-0.855,1.382-1.639
      c0-1.179-1.311-1.562-1.732-1.685c-0.906-0.284-1.031-0.52-1.031-0.732c0-0.391,0.396-0.708,0.881-0.708
      c0.328,0,0.627,0.146,0.782,0.383c0.15,0.231,0.461,0.296,0.692,0.145s0.296-0.46,0.145-0.692C6.453,4.423,6.046,4.15,5.583,4.033
      V3.809c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v0.229c-0.794,0.2-1.381,0.855-1.381,1.639c0,1.143,1.214,1.523,1.741,1.688
      c1.022,0.299,1.022,0.611,1.022,0.728c0,0.391-0.396,0.708-0.882,0.708c-0.353,0-0.896-0.233-1.237-0.53
      C3.639,8.092,3.322,8.113,3.141,8.32c-0.182,0.208-0.16,0.524,0.048,0.706C3.575,9.362,4.098,9.621,4.583,9.735z"/>
    <path
      d="M23.552,2h-2c-0.276,0-0.5,0.224-0.5,0.5v21c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5v-21
      C24.052,2.223,23.828,2,23.552,2z M23.052,23h-1V3h1V23z"/>
    <path
      d="M18.551,11.001h-2c-0.276,0-0.5,0.224-0.5,0.5V23.5c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5
      V11.501C19.051,11.224,18.827,11.001,18.551,11.001z M18.051,23h-1V12.001h1V23z"/>
    <path
      d="M13.551,16.001h-2c-0.276,0-0.5,0.224-0.5,0.5V23.5c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5
      v-6.999C14.051,16.224,13.827,16.001,13.551,16.001z M13.051,23h-1v-5.999h1V23z"/>
    <path
      d="M8.551,19.001h-2c-0.276,0-0.5,0.224-0.5,0.5V23.5c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5
      v-3.999C9.051,19.224,8.827,19.001,8.551,19.001z M8.051,23h-1v-2.999h1V23z"/>
    <path
      d="M3.55,20.001h-2c-0.276,0-0.5,0.224-0.5,0.5V23.5c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5
      v-2.999C4.05,20.224,3.827,20.001,3.55,20.001z M3.05,23h-1v-1.999h1V23z"/>
  </svg>
</template>
